.PrivatePickersSlideTransition-root {
  min-height: 200px !important;
}

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-thumb {
  background: #666e7c !important;
}

.ql-editor {
  overflow-y: scroll;
  resize: vertical;
  min-height: 210px;
}

.jobPostCkEditor .ql-editor {
  min-height: 100px;
  border: none;
  resize: none;
}

.ckEditorPreview .ql-toolbar {
  display: none;
}
.ckEditorPreview .ql-editor {
  border: 1.5px solid #a2a0a0;
}

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  background-color: darkgray;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused {
  background-color: darkgray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
